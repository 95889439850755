
var myApp = angular.module('myApp', ['ngRoute', 'ngCookies', 'ui.date', 'angular-loading-bar', 'smart-table', 'ui.bootstrap', 'nya.bootstrap.select', 'angularMoment', 'uiSwitch', 'chart.js', 'xeditable', 'ngSanitize', 'jcs-autoValidate', 'angular-input-stars', 'ng.group', 'angular-country-select', 'pusher-angular', 'ui-notification', 'ngTouch', 'pretty-checkable', 'ng-slide-down']);

myApp.config(['$routeProvider', '$locationProvider',
	
	function($routeProvider, $locationProvider) {
		$routeProvider.when('/', {
			templateUrl: 'templates/users/login.html',
			controller: 'userController'
		});

		$routeProvider.when('/changelog', {
			templateUrl: 'templates/changelog/changelog.html',
		});

		$routeProvider.when('/dashboard', {
			templateUrl: 'templates/users/dashboard.html',
			controller: 'dashboardController',
			authenticated: true
		});

		$routeProvider.when('/dashboard_preggo', {
			templateUrl: 'templates/users/dashboard_preggo.html',
			controller: 'dashboardController',
			authenticated: true
		});

		$routeProvider.when('/logout', {
			templateUrl: 'templates/users/logout.html',
			controller: 'userController',
			authenticated: true
		});

		// PERFORMANCE SECTION----------------------------------------------------------

		$routeProvider.when('/dashboard_text', {
			templateUrl: 'templates/performance/performance.html',
			controller: 'PerformanceController',
			authenticated: true
		});

		$routeProvider.when('/performance/problem', {
			templateUrl: 'templates/performance/performance.html',
			controller: 'PerformanceController',
			authenticated: true
		});

		$routeProvider.when('/performance/highlights', {
			templateUrl: 'templates/performance/performance_highlights.html',
			controller: 'PerformanceHighlightsController',
			authenticated: true
		});
		
		$routeProvider.when('/performance/weekly', {
			templateUrl: 'templates/performance/performance_weekly.html',
			controller: 'PerformanceWeeklyController',
			authenticated: true
		});

		$routeProvider.when('/performance/archive', {
			templateUrl: 'templates/performance/performance_archive.html',
			controller: 'PerformanceArchiveController',
			authenticated: true
		});




		// END PERFORMANCE SECTION----------------------------------------------------------


		//ORDERS MANAGE
		$routeProvider.when('/orders/manage', {
			templateUrl: 'templates/orders/manage.html',
			controller: 'orderController',
			authenticated: true
		});
		$routeProvider.when('/orders/new-adjudication', {
			templateUrl: 'templates/orders/new-adjudication.html',
			controller: 'addRegisterController',
			authenticated: true
		});
		$routeProvider.when('/orders/new-stock', {
			templateUrl: 'templates/orders/new-stock.html',
			controller: 'addRegisterController',
			authenticated: true
		});
		$routeProvider.when('/orders/manage_adjudication', {
			templateUrl: 'templates/orders/manage_adjudication.html',
			controller: 'orderController',
			authenticated: true
		});

		// SEARCH SECTION ####################################_-------------
		$routeProvider.when('/orders/search', {
			templateUrl: 'templates/search/search.html',
			controller: 'searchController',
			authenticated: true
		});

		$routeProvider.when('/orders/search_salesman', {
			templateUrl: 'templates/search/search_salesman.html',
			controller: 'searchController',
			authenticated: true
		});

		$routeProvider.when('/orders/search_preggo', {
			templateUrl: 'templates/search/search_preggo.html',
			controller: 'searchController',
			authenticated: true
		});


		// END SEARCH SECTION ####################################_-------------

		//QUEUE ORDERS-------------------------------------------------------------
		$routeProvider.when('/orders/queue', {
			templateUrl: 'templates/orders/queue.html',
			controller: 'queueController',
			authenticated: true

		});

		$routeProvider.when('/orders/queue_history', {
			templateUrl: 'templates/orders/queue_history.html',
			controller: 'queueHistoryController',
			authenticated: true

		});

		//IMPARIDADE
		$routeProvider.when('/imparidade', {
			templateUrl: 'templates/imparidade/imparidade.html',
			controller: 'imparidadeController',
			authenticated: true

		});

		//PRODUCT MANAGE------------------------------------------------------------
		$routeProvider.when('/product/manage', {
			templateUrl: 'templates/products/product_manage.html',
			controller: 'productController',
			authenticated: true
		});
		//COSTUMER MANAGE
		$routeProvider.when('/costumer/manage', {
			templateUrl: 'templates/costumer/costumer_manage.html',
			controller: 'costumerRelationController',
			authenticated: true
		});
		//PROVIDERS MANAGE
		$routeProvider.when('/providers/manage', {
			templateUrl: 'templates/providers/provider_manage.html',
			controller: 'providersController',
			authenticated: true
		});

		//PREGGO
		$routeProvider.when('/orders/manage_preggo', {
			templateUrl: 'templates/orders/manage_preggo.html',
			controller: 'orderController',
			authenticated: true
		});
		$routeProvider.when('/people_preggo/manage', {
			templateUrl: 'templates/costumer/preggo_people_manage.html',
			controller: 'responsavelPreggoController',
			authenticated: true
		});

		//VENDEDOR
		$routeProvider.when('/orders/manage_salesman', {
			templateUrl: 'templates/orders/manage_salesman.html',
			controller: 'orderController',
			authenticated: true
		});

		//ARCHIVE
		$routeProvider.when('/orders/archive', {
			templateUrl: 'templates/archive/archive.html',
			controller: 'archiveController',
			authenticated: true
		});
		$routeProvider.when('/orders/archive_salesman', {
			templateUrl: 'templates/archive/archive_salesman.html',
			controller: 'archiveController',
			authenticated: true
		});
		$routeProvider.when('/orders/archive_adjudication', {
			templateUrl: 'templates/archive/archive_adjudication.html',
			controller: 'archiveController',
			authenticated: true
		});

		//PROFILE SETTINGS
		$routeProvider.when('/settings', {
			templateUrl: 'templates/users/settings.html',
			controller: 'settingsController',
			authenticated: true
		});

		//CALENDAR
		$routeProvider.when('/calendar', {
			templateUrl: 'templates/calendar/calendar.html',
			controller: 'calendarController',
			authenticated: true

		});

		$routeProvider.when('/calendar_overview', {
			templateUrl: 'templates/calendar/calendar_overview.html',
			controller: 'calendarOverviewController',
			authenticated: true

		});
		$routeProvider.when('/calendar_360', {
			templateUrl: 'templates/calendar/calendar_360.html',
			controller: 'calendarController360',
			authenticated: true

		});

		// SPV SECTION ####################################_-------------
		$routeProvider.when('/spv_form', {
			templateUrl: 'templates/spv/form_spv.html',
			controller: 'spvController',
			authenticated: true

		});
		$routeProvider.when('/spv_analyze', {
			templateUrl: 'templates/spv/form_analyze.html',
			controller: 'spvPhase2Controller',
			authenticated: true

		});
		$routeProvider.when('/spv_curso', {
			templateUrl: 'templates/spv/spv_phase_3.html',
			controller: 'spvPhase3Controller',
			authenticated: true

		});
		$routeProvider.when('/spv/unique', {
			templateUrl: 'templates/spv/spv_unique_case.html',
			controller: 'spvUniqueController',
			authenticated: true
		});
		$routeProvider.when('/spv/reproved', {
			templateUrl: 'templates/spv/spv_reproved.html',
			controller: 'spvReprovedController',
			authenticated: true
		});
		$routeProvider.when('/spv/archive', {
			templateUrl: 'templates/spv/spv_archive.html',
			controller: 'spvArchivedController',
			authenticated: true
		});

		$routeProvider.otherwise('/');

		//$locationProvider.html5Mode(true);
	}
]);

myApp.run(["$rootScope", "$location", 'userModel',
	function($rootScope, $location, userModel) {
		$rootScope.$on("$routeChangeStart",
			function(event, next, current) {
				if(next.$$route.authenticated) {
					if(!userModel.getAuthStatus()) {
						$location.path('/');
					}
				}
				if (next.$$route.originalPath == '/') {
					if(userModel.getAuthStatus()) {
						$location.path(current.$$route.originalPath);
					}
				}
			});
		}
]);

//STICKY HEADER TO TABLES
myApp.directive('stStickyHeader', ['$window', function ($window) {
    return {
      require: '^?stTable',
      link: function (scope, element, attr, ctrl) {
        var stickyHeader = lrStickyHeader(element[0]);
        scope.$on('$destroy', function () {
          stickyHeader.clean();
        });

        scope.$watch(function () {
          return ctrl.tableState();
        }, function () {
          $window.scrollTo(0, lrStickyHeader.treshold);
        }, true)
      }
    }
  }]);

//WIDTH TO TABLES
myApp.directive('stRatio',function(){
        return {
          link:function(scope, element, attr){
            var ratio=+(attr.stRatio);
            
            element.css('width',ratio+'%');
            
          }
        };
});

myApp.directive("csDateToIso", function () {

    var linkFunction = function (scope, element, attrs, ngModelCtrl) {

        ngModelCtrl.$parsers.push(function (datepickerValue) {
            return moment(datepickerValue).format("YYYY-MM-DD");
        });
    };

    return {
        restrict: "A",
        require: "ngModel",
        link: linkFunction
    };
});

myApp.run(function(editableOptions, editableThemes) {
	editableThemes.bs3.inputClass = 'input-xs';
  editableThemes.bs3.buttonsClass = 'btn-min';
  editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'
});

myApp.run([
        'bootstrap3ElementModifier',
        function (bootstrap3ElementModifier) {
              bootstrap3ElementModifier.enableValidationStateIcons(true);
       }]);

myApp.directive("formatDate", function(){
  return {
   require: 'ngModel',
    link: function(scope, elem, attr, modelCtrl) {
      modelCtrl.$formatters.push(function(modelValue){
        return new Date(modelValue);
      })
    }
  }
});
myApp.directive('stringToNumber', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(value) {
        return '' + value;
      });
      ngModel.$formatters.push(function(value) {
        return parseFloat(value, 10);
      });
    }
  };
});

myApp.directive('convertToNumber', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(val) {
        return val ? parseInt(val, 10) : null;
      });
      ngModel.$formatters.push(function(val) {
        return val ? '' + val : null;
      });
    }
  };
});
myApp.filter('decimal2comma', [
function() {// should be altered to suit your needs
    return function(input) {
        var ret=(input)?input.toString().replace(".",","):null;
        if(ret){
            var decArr=ret.split(",");
            if(decArr.length>1){
                var dec=decArr[1].length;
                if(dec===1){ret+="0";}
            }//this is to show prices like 12,20 and not 12,2
        }
        else
        {
        	ret = 0;
        }
        return ret;
    };
}]);
myApp.directive('pageSelect', function() {
      return {
        restrict: 'E',
        template: '<input type="text" class="select-page" style="width: 30px; height:16px; text-align:center" ng-model="inputPage" ng-change="selectPage(inputPage)">',
        link: function(scope, element, attrs) {
          scope.$watch('currentPage', function(c) {
            scope.inputPage = c;
          });
        }
      }
});

myApp.directive('parentHeight', function() {
	return {
		restrict: 'A',
		link:function(scope, $element, attrs) {
		scope.$watch( function() {
    	$element.css("height", $element.parent().height() + 'px');
    	$element.parent().css("height", $element.parent().height() + 'px');
    	console.log($element.parent().height());
		});
  }
};
   
});
myApp.filter('sumOfValue', function () {
    return function (data, key) {
        if (angular.isUndefined(data) && angular.isUndefined(key))
            return 0;        
        var sum = 0;
        
        angular.forEach(data,function(v,k){
            sum = sum + parseInt(v[key]);
        });        
        return sum;
    }
});

myApp.filter('orderObjectBy', function() {
  return function(items, field, reverse) {
    var filtered = [];
    angular.forEach(items, function(item) {
      filtered.push(item);
    });
    filtered.sort(function (a, b) {
      return (a[field] > b[field] ? 1 : -1);
    });
    if(reverse) filtered.reverse();
    return filtered;
  };
});

myApp.config(function(NotificationProvider) {
        NotificationProvider.setOptions({
            delay: 15000,
            startTop: 60,
            startRight: 10,
            verticalSpacing: 15,
            horizontalSpacing: 15,
            positionX: 'right',
            positionY: 'top'
        });
 });

//DROPZONE CONFIG
myApp.directive('dropzone', function() {
	return function (scope, element, attrs) {
    var config, dropzone;

    config = scope[attrs.dropzone];

    // create a Dropzone for the element with the given options
    dropzone = new Dropzone(element[0], config.options);

    // bind the given event handlers
    angular.forEach(config.eventHandlers, function (handler, event) {
      dropzone.on(event, handler);
    });
	};

});








 


